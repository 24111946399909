import { AnyAction } from 'redux';
import { ISageTransportParams, QoE } from '@tivocorp/tivo-webapp-loggers';
import { OverlayNavigationTypes } from '../../core/infrastructure/navigation/OverlayNavigationTypes';
import {
    APP_START,
    AUTH_TOKEN_STORE,
    TURN_OFF_INITIAL_WELCOME,
    TURN_ON_INITIAL_WELCOME,
    SET_EXIT_PLAYER_TIMER,
    UPDATE_STILL_THERE_TIMER,
    SET_NAVIGATION_HANDLER,
    VERIFY_PARENTAL_CONTROL_PIN,
    STORE_STATION_ID,
    SET_OVERLAY_NAVIGATION_TYPE,
} from './Appl.types';
import {
    appStartAction,
    clearExitPlayerTimerAction,
    setExitPlayerTimerAction,
    storeAuthTokenAction,
    turnOnInitalWelcomeAction,
    updateStillThereTimerAction,
    setNavigationHandlerAction,
    turnOffInitalWelcomeAction,
    verifiedParentalControlPinAction,
    storeStationIdAction,
    setOverlayNavigationTypeAction,
} from './Appl.actions';
import { fetchStationData } from '../stationdata/StationData.reducers';
import { ApplRepositoryImpl } from '../../core/infrastructure/appl/ApplRepositoryImpl';
import { ApplImpl } from '../../core/usecases/appl/ApplImpl';
import {
    cantPlayOverlayShow,
    internetConnectionLostOverlayHide,
    internetConnectionLostOverlayShow,
    stillThereOverlayHide,
    stillThereOverlayShow,
} from '../messageoverlay/MessageOverlay.reducers';
import { EXIT_PLAYER_THRESHOLD_TIME, STILL_THERE_THRESHOLD_TIME } from '../../constants';
import { Log } from '../../core/logging/Log';
import { NavigationHandlers } from '../../core/infrastructure/navigation/NavigationTypes';
import { APS_AUTH_SECRET, APS_AUTH_TOKEN_URL, PARENTAL_CONTROL_PIN } from '../../strings';
import {
    enterPinOverlayHide,
    enterPinOverlayShow,
    invalidParentalControlPin,
    validParentalControlPin,
} from '../pinoverlay/PinOverlay.reducers';
import ParentalControlImpl from '../../core/infrastructure/parentalcontrol/ParentalControlImpl';
import { fetchMetaData } from '../metadata/MetaData.reducers';
import { QoEAppLogger } from '../../core/logging/QoEAppLogger';
import { IDeviceParams } from '../../core/logging/IDeviceParams';
import {
    APP_NAME,
    APP_VERSION,
    DEVICE_ID,
    DEVICE_TYPE,
    DEVICE_TSN,
    AD_TRACKING_ENABLED,
    MSO_NAME,
    MSO_PARTNER_ID,
    USER_ID,
    CONNECTION,
} from '../../config';
import { startVideoPlayback } from '../shakaplayer/ShakaPlayer.reducers';
import { storeStationDataAction } from '../stationdata/StationData.actions';

const TAG = 'appl';
const urlParams = new URLSearchParams(window.location.search);
const parentalControl = new ParentalControlImpl(PARENTAL_CONTROL_PIN);

const initialState: {
    isWelcomeOn: boolean;
    authToken: string;
    stillThereTimer: any;
    exitPlayerTimer: any;
    navigationHandler: string;
    hasVerifiedParentalControlPin: boolean;
    stationId: string;
    overlayNavigationType: string;
} = {
    isWelcomeOn: true,
    authToken: '',
    stillThereTimer: null,
    exitPlayerTimer: null,
    navigationHandler: NavigationHandlers.ApplHandler,
    hasVerifiedParentalControlPin: false,
    stationId: '',
    overlayNavigationType: OverlayNavigationTypes.DEFAULT,
};

const applReducer = (state = initialState, { type, payload } = <AnyAction>{}) => {
    switch (type) {
        case APP_START:
            return state;

        case TURN_ON_INITIAL_WELCOME:
        case TURN_OFF_INITIAL_WELCOME:
            return {
                ...state,
                isWelcomeOn: payload.isWelcomeOn,
            };

        case AUTH_TOKEN_STORE:
            return {
                ...state,
                authToken: payload.authToken,
            };

        case UPDATE_STILL_THERE_TIMER:
            return {
                ...state,
                stillThereTimer: payload.stillThereTimer,
            };

        case SET_EXIT_PLAYER_TIMER:
            return {
                ...state,
                exitPlayerTimer: payload.exitPlayerTimer,
            };

        case SET_NAVIGATION_HANDLER:
            return {
                ...state,
                navigationHandler: payload.navigationHandler,
            };

        case VERIFY_PARENTAL_CONTROL_PIN:
            return {
                ...state,
                hasVerifiedParentalControlPin: payload.hasVerifiedParentalControlPin,
            };

        case STORE_STATION_ID:
            return {
                ...state,
                stationId: payload.stationId,
            };
        case SET_OVERLAY_NAVIGATION_TYPE:
            return {
                ...state,
                overlayNavigationType: payload.overlayNavigationType,
            };

        default:
            return state;
    }
};

export const appStart = () => (dispatch: Function) => {
    const deviceParams: IDeviceParams = {
        appName: APP_NAME,
        appVersion: APP_VERSION,
        deviceId: DEVICE_ID,
        deviceType: DEVICE_TYPE,
        deviceTsn: DEVICE_TSN,
        adTrackingEnabled: AD_TRACKING_ENABLED,
        msoName: MSO_NAME,
        msoPartnerId: MSO_PARTNER_ID,
        userId: USER_ID,
        loggingVersion: '2.0.1',
        connection: CONNECTION,
    };
    const sageParams: ISageTransportParams = {
        tokenSalt: 'Sage1O:',
        appName: 0xc,
        device: 0x6,
        os: 0x5,
        developmentPhase: 0x1,
        partnerId: 0x0c17,
        deviceFamily: 0x6,
        hecEndpoint: 'https://sage-hec.oi.tivo.com:8088/services/collector/event',
        mediaHealthEventsInterval: 300 * 1000, // 5 minutes
        loggingVersion: '2.0.1',
    };

    QoEAppLogger.setGlobalParams(deviceParams);
    QoEAppLogger.setSageTransportParams(sageParams);
    QoE.logApplicationStart();
    dispatch(appStartAction());
    dispatch(turnOnInitalWelcomeAction());

    // TODO: Actual implementation of Enter Pin Overlay yet to be decided
    // NOTE: This will be removed when deep link parser get implemented.
    const pinStatus = urlParams.get('pinStatus') === 'true';

    if (pinStatus) {
        dispatch(enterPinOverlayShow());
    } else {
        dispatch(continuePlayback());
    }
};

export const updateStillThereTimer = () => (dispatch: Function, getState: Function) => {
    const { appl, messageOverlay, pinOverlay } = getState();
    const { stillThereTimer, exitPlayerTimer } = appl;
    const { isStillThereOverlayActive } = messageOverlay;
    const { isEnterPinOverlayActive } = pinOverlay;
    if (!isEnterPinOverlayActive) {
        if (isStillThereOverlayActive) dispatch(stillThereOverlayHide());
        if (stillThereTimer) clearTimeout(stillThereTimer);
        if (exitPlayerTimer) clearTimeout(exitPlayerTimer);
        const timer = setTimeout(() => {
            Log.i(TAG, 'Reached the still there time limit.');
            dispatch(stillThereOverlayShow());
            dispatch(setExitPlayerTimer());
        }, STILL_THERE_THRESHOLD_TIME);
        dispatch(updateStillThereTimerAction(timer));
    }
};

export const setExitPlayerTimer = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    const { exitPlayerTimer } = appl;
    if (exitPlayerTimer) clearTimeout(exitPlayerTimer);
    const timer = setTimeout(() => {
        // TODO: Implement the return to home app
        Log.i(TAG, 'Return to home app.');
        dispatch(stillThereOverlayHide());
    }, EXIT_PLAYER_THRESHOLD_TIME);
    dispatch(setExitPlayerTimerAction(timer));
};

export const clearExitPlayerTimer = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    const { exitPlayerTimer } = appl;
    if (exitPlayerTimer) clearTimeout(exitPlayerTimer);
    dispatch(clearExitPlayerTimerAction());
};

export const setNavigationHandler = (handler: string) => (dispatch: Function) => {
    dispatch(setNavigationHandlerAction(handler));
};

export const turnOffInitalWelcome = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    /* This is called every time Playing event is received from shaka.
    Added check Just to make sure to call it only once */
    if (appl.isWelcomeOn) {
        dispatch(turnOffInitalWelcomeAction());
        if (
            appl.navigationHandler !== NavigationHandlers.ActionStripHandler &&
            appl.navigationHandler !== NavigationHandlers.OverlayNavigationHandler
        )
            dispatch(setNavigationHandler(NavigationHandlers.ApplHandler));
    }
};

export const internetConnectionLost = () => (dispatch: Function, getState: Function) => {
    const { pinOverlay } = getState();
    const { isEnterPinOverlayActive } = pinOverlay;
    if (isEnterPinOverlayActive) dispatch(enterPinOverlayHide());
    dispatch(internetConnectionLostOverlayShow());
};

export const internetConnectionBack = () => (dispatch: Function, getState: Function) => {
    const { appl, metaData } = getState();
    const { hasVerifiedParentalControlPin } = appl;
    const { metaData: currentMetadata } = metaData;
    const isMetaDataUpdateRequired =
        currentMetadata.length && currentMetadata[0].endTimestamp * 1000 - new Date().getTime() < 0;
    dispatch(setNavigationHandler(NavigationHandlers.ApplHandler));
    dispatch(internetConnectionLostOverlayHide());
    // TODO: This will be removed when deep link parser get implemented.
    const pinStatus = urlParams.get('pinStatus') === 'true';
    if (pinStatus && !hasVerifiedParentalControlPin) {
        dispatch(enterPinOverlayShow());
    }
    if (isMetaDataUpdateRequired) dispatch(fetchMetaData());
};

export const verifyParentalControlPin = () => (dispatch: Function, getState: Function) => {
    const { pinOverlay } = getState();
    const { parentalControlPin } = pinOverlay;

    if (parentalControl.verify(parentalControlPin.join(''))) {
        dispatch(verifiedParentalControlPinAction());
        dispatch(validParentalControlPin());
        dispatch(enterPinOverlayHide());
        dispatch(setNavigationHandler(NavigationHandlers.ApplHandler));
        dispatch(continuePlayback());
    } else {
        dispatch(invalidParentalControlPin());
        dispatch(setNavigationHandler(NavigationHandlers.PinDialogHandler));
    }
};

const continuePlayback = () => (dispatch: Function) => {
    dispatch(updateStillThereTimer());
    // TODO: This will be moved to URL parser or deep link parser.
    const stationId = urlParams.get('stationId');
    const streamUrl = urlParams.get('streamUrl');
    if (stationId) {
        dispatch(storeStationIdAction(stationId));
        const applRepositoryImpl = new ApplRepositoryImpl(APS_AUTH_TOKEN_URL, APS_AUTH_SECRET);
        const applImpl = new ApplImpl(applRepositoryImpl);
        applImpl.getAuthToken().then((authToken: string) => {
            dispatch(storeAuthTokenAction(authToken));
            dispatch(fetchStationData());
        });
    } else if (streamUrl) {
        dispatch(startVideoPlayback(streamUrl));
        dispatch(storeStationDataAction([], streamUrl));
    } else dispatch(cantPlayOverlayShow());
};

export const setOverlayNavigationType = (overlayNavigation: string) => (dispatch: Function) => {
    dispatch(setOverlayNavigationTypeAction(overlayNavigation));
};

export default applReducer;
