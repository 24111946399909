import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { QoE } from '@tivocorp/tivo-webapp-loggers';
import { QoEAppLogger } from '../../core/logging/QoEAppLogger';
import { turnOffInitalWelcome } from '../../store/appl/Appl.reducers';
import { RootState } from '../../store/root.reducers';
import {
    retryVideoPlayback,
    resetPlaybackRetry,
    stopVideoPlayback,
} from '../../store/shakaplayer/ShakaPlayer.reducers';
import shakaConfig from './ShakaPlayerConfig';
import './ShakaPlayer.css';
import { Log } from '../../core/logging/Log';
import { cantPlayOverlayShow } from '../../store/messageoverlay/MessageOverlay.reducers';

const shaka = require('shaka-player/dist/shaka-player.compiled');
// TODO: Uncomment this when using the shaka-player from tivo-corp and remove the above line
// const shaka = require('shaka-player/shaka-player.compiled');

shaka.polyfill.installAll();

const ShakaPlayer: FunctionComponent = (): JSX.Element => {
    const TAG = 'ShakaPlayer';
    const { shakaPlayer, stationData, metaData } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const { playUrl, cc, retry } = shakaPlayer;
    const uiContainerRef = useRef(null);
    const videoRef = useRef(null);
    const [videoPlayer, setVideoPlayer] = useState<any>(null);
    const isVideoPlayerActive = useRef(false);
    const RETRY_LIMIT = 5;

    // Effect to handle component mount & unmount.
    // Not related to the src prop, this hook creates a shaka.Player instance.
    // This should always be the first effect to run.
    useEffect(() => {
        setVideoPlayer(new shaka.Player(videoRef.current));
    }, [videoRef]);

    useEffect(() => {
        if (videoPlayer && shakaConfig) {
            videoPlayer.configure(shakaConfig);
            registerEventListeners(videoPlayer);
        }
    }, [videoPlayer, shakaConfig]);

    useEffect(() => {
        if (videoPlayer) {
            videoPlayer.setTextTrackVisibility(cc);
            const activeTextTrack = videoPlayer
                .getTextTracks()
                .filter((textTrack: { active: any }) => textTrack.active)[0];
            if (activeTextTrack) {
                const textTrackDetails = {
                    language: activeTextTrack.language,
                    mimeType: activeTextTrack.mimeType,
                };
                Log.i(TAG, 'Text track details: ', JSON.stringify(textTrackDetails));
            }
        }
    }, [videoPlayer, cc]);

    useEffect(() => {
        if (videoPlayer && isVideoPlayerActive.current === false) {
            videoPlayer
                .load(playUrl)
                .then(() => {
                    // This runs if the asynchronous load is successful.
                    const { channelData } = stationData;
                    const { metaData: oMetaData } = metaData;
                    QoEAppLogger.createStreamingSessionId();
                    QoEAppLogger.setVideoPlayerUrl(playUrl);
                    QoE.logSessionCreated(QoEAppLogger.createSessionStartedPayload());
                    QoE.logStreamingStart(
                        QoEAppLogger.createStreamingSessionPayload(channelData, oMetaData),
                    );
                    Log.i(TAG, 'The video has now been loaded!');
                    videoPlayer.getMediaElement().play();
                    QoE.logVideoPlayerOpenStream(QoEAppLogger.createPlayerStatePayload());
                    dispatch(resetPlaybackRetry());
                    isVideoPlayerActive.current = true;
                })
                .catch((err) => {
                    onError(err);
                    if (retry < RETRY_LIMIT) {
                        videoPlayer.unload();
                        Log.d(TAG, 'Retry attemp:', retry);
                        setTimeout(() => {
                            dispatch(retryVideoPlayback());
                        }, 3000);
                    } else {
                        dispatch(cantPlayOverlayShow());
                        dispatch(stopVideoPlayback(QoEAppLogger.PlaybackEndReasons.PLAYER_ERROR));
                        Log.e(TAG, 'Failed to start the playback');
                    }
                });
        }
    }, [videoPlayer, playUrl, retry]);

    const onError = (error) => {
        // Log the error.
        Log.d(TAG, 'Error code', error.code, 'object', error);
    };

    const registerEventListeners = (player) => {
        const videoElement: any = videoRef.current;
        videoElement.addEventListener('playing', () => {
            Log.i(TAG, 'PlayerEvent: Playing');
            dispatch(turnOffInitalWelcome());
            QoE.logFirstFrame(QoEAppLogger.createPlayerStatePayload());
        });

        player.addEventListener('trackschanged', () => {
            Log.i(TAG, 'PlayerEvent: trackschanged');
        });

        player.addEventListener('error', (event) => {
            Log.e(TAG, 'PlayerEvent: error');
            onError(event.detail);
        });

        player.addEventListener('buffering', () => {
            Log.i(TAG, 'PlayerEvent: buffering');
        });

        player.addEventListener('emsg', () => {
            Log.i(TAG, 'PlayerEvent: emsg');
        });

        player.addEventListener('unloading', () => {
            Log.i(TAG, 'PlayerEvent: unloading');
        });

        player.addEventListener('streaming', () => {
            Log.i(TAG, 'PlayerEvent: streaming');
        });

        player.addEventListener('onstatechange', () => {
            Log.i(TAG, 'PlayerEvent: onstatechange');
        });

        player.addEventListener('onstateidle', () => {
            Log.i(TAG, 'PlayerEvent: onstateidle');
            const activeVariant = videoPlayer
                .getVariantTracks()
                .filter((variant: { active: any }) => variant.active)[0];
            if (activeVariant) {
                const variantDetails = {
                    audiocodec: activeVariant.audioCodec,
                    videoCodec: activeVariant.videoCodec,
                    mimeType: activeVariant.mimeType,
                    language: activeVariant.language,
                };
                Log.i(TAG, 'Active variant details: ', JSON.stringify(variantDetails));
            }
        });

        player.addEventListener('ratechange', () => {
            Log.i(TAG, 'PlayerEvent: ratechange');
        });

        player.addEventListener('metadata', () => {
            Log.i(TAG, 'PlayerEvent: metadata found');
        });

        player.addEventListener('manifestparsed', () => {
            Log.i(TAG, 'PlayerEvent: manifestparsed');
        });
    };

    return (
        <div ref={uiContainerRef}>
            <video className="shaka-player" ref={videoRef} autoPlay></video>
        </div>
    );
};

export default ShakaPlayer;
