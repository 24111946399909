import { IApplRepository } from '../../entities/appl/IApplRepository';

export class ApplImpl implements IApplRepository {
    applRepo: IApplRepository;

    constructor(applRepo: IApplRepository) {
        this.applRepo = applRepo;
    }

    async getAuthToken(): Promise<string> {
        return this.applRepo.getAuthToken();
    }
}
