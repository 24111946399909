import React, { FunctionComponent, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    onKeyUp,
    onKeyDown,
    onKeyLeft,
    onKeyRight,
    onKeyTivo,
    onKeyOk,
    onKeyDigit,
    onKeyInfo,
    onKeyBack,
} from '../../store/inputfactory/inputFactory.reducer';
import { Log } from '../../core/logging/Log';
import { onKeyEventAction } from '../../store/inputfactory/inputFactory.actions';
import { Keys } from '../../core/infrastructure/navigation/Keys';
import { updateStillThereTimer } from '../../store/appl/Appl.reducers';

const KeyboardEvents: FunctionComponent = ({ children }): JSX.Element => {
    const TAG = 'KeyboardEvents';
    const dispatch = useDispatch();
    const navigationHandle = (event: KeyboardEvent): boolean => {
        const k = event.key.toLowerCase();
        const allowed = [
            Keys.KEYBOARD_KEY_UP,
            Keys.KEYBOARD_KEY_DOWN,
            Keys.KEYBOARD_KEY_LEFT,
            Keys.KEYBOARD_KEY_RIGHT,
            Keys.KEYBOARD_KEY_TIVO,
            Keys.KEYBOARD_KEY_INFO,
            Keys.KEYBOARD_KEY_OK,
            Keys.KEYBOARD_KEY_0,
            Keys.KEYBOARD_KEY_1,
            Keys.KEYBOARD_KEY_2,
            Keys.KEYBOARD_KEY_3,
            Keys.KEYBOARD_KEY_4,
            Keys.KEYBOARD_KEY_5,
            Keys.KEYBOARD_KEY_6,
            Keys.KEYBOARD_KEY_7,
            Keys.KEYBOARD_KEY_8,
            Keys.KEYBOARD_KEY_9,
            Keys.KEYBOARD_KEY_BACK,
            // TODO: we need to include remote keys.
        ];

        if (allowed.indexOf(k) < 0) {
            return false;
        }

        dispatch(onKeyEventAction(event.key, event.code));
        dispatch(updateStillThereTimer());

        Log.i(TAG, 'key =', k);

        switch (k) {
            case Keys.KEYBOARD_KEY_LEFT:
                dispatch(onKeyLeft());
                break;
            case Keys.KEYBOARD_KEY_RIGHT:
                dispatch(onKeyRight());
                break;
            case Keys.KEYBOARD_KEY_DOWN:
                dispatch(onKeyDown());
                break;
            case Keys.KEYBOARD_KEY_UP:
                dispatch(onKeyUp());
                break;
            case Keys.KEYBOARD_KEY_TIVO:
                dispatch(onKeyTivo());
                break;
            case Keys.KEYBOARD_KEY_INFO:
                dispatch(onKeyInfo());
                break;
            case Keys.KEYBOARD_KEY_OK:
                dispatch(onKeyOk());
                break;
            case Keys.KEYBOARD_KEY_0:
            case Keys.KEYBOARD_KEY_1:
            case Keys.KEYBOARD_KEY_2:
            case Keys.KEYBOARD_KEY_3:
            case Keys.KEYBOARD_KEY_4:
            case Keys.KEYBOARD_KEY_5:
            case Keys.KEYBOARD_KEY_6:
            case Keys.KEYBOARD_KEY_7:
            case Keys.KEYBOARD_KEY_8:
            case Keys.KEYBOARD_KEY_9:
                dispatch(onKeyDigit());
                break;
            case Keys.KEYBOARD_KEY_BACK:
                dispatch(onKeyBack());
                break;
            default:
                return false;
        }

        return true;
    };
    useEffect(() => {
        document.addEventListener('keyup', navigationHandle);
        return () => {
            document.removeEventListener('keyup', navigationHandle);
        };
    }, []);

    return <div>{children}</div>;
};

KeyboardEvents.propTypes = {
    children: PropTypes.node.isRequired,
};

export default KeyboardEvents;
