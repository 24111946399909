import { AnyAction } from 'redux';
import { Payload, QoE } from '@tivocorp/tivo-webapp-loggers';
import { Log } from '../../core/logging/Log';
import { QoEAppLogger } from '../../core/logging/QoEAppLogger';
import {
    SHAKA_PLAYER_START_PLAYBACK,
    SHAKA_PLAYER_RESTART_PLAYBACK,
    SHAKA_PLAYER_TOGGLE_CLOSED_CAPTION,
    SHAKA_PLAYER_RESET_RETRY,
} from './ShakaPlayer.types';
import {
    shakaPlayerStartPlaybackAction,
    shakaPlayerRestartPlaybackAction,
    shakaPlayerSetClosedCaptionAction,
    shakaPlayerResetRetryAction,
} from './ShakaPlayer.actions';

const initialState: {
    playUrl: string;
    cc: boolean;
    retry: number;
} = {
    playUrl: '',
    cc: false,
    retry: 0,
};

const shakaPlayerReducer = (state = initialState, { type, payload } = <AnyAction>{}) => {
    switch (type) {
        case SHAKA_PLAYER_START_PLAYBACK:
            return {
                ...state,
                playUrl: payload.playUrl,
                retry: 0,
            };

        case SHAKA_PLAYER_RESTART_PLAYBACK:
            return {
                ...state,
                playUrl: payload.playUrl,
                retry: payload.retry,
            };

        case SHAKA_PLAYER_TOGGLE_CLOSED_CAPTION:
            return {
                ...state,
                cc: payload.cc,
            };

        case SHAKA_PLAYER_RESET_RETRY:
            return {
                ...state,
                retry: 0,
            };

        default:
            return state;
    }
};

export const startVideoPlayback = (playUrl: string) => (dispatch: Function) => {
    dispatch(shakaPlayerStartPlaybackAction(playUrl));
};

export const qoeWatchContentStart = () => (dispatch: Function, getState: Function) => {
    const { stationData, metaData: oMetadata } = getState();
    const { channelData } = stationData;
    const { metaData } = oMetadata;
    QoEAppLogger.createWatchContentId();
    QoE.logWatchContentStart(QoEAppLogger.createWatchContentPayload(channelData, metaData));
};

export const retryVideoPlayback = () => (dispatch: Function, getState: Function) => {
    const { shakaPlayer } = getState();
    const { playUrl, retry } = shakaPlayer;
    dispatch(shakaPlayerRestartPlaybackAction(playUrl, retry + 1));
};

export const toggleClosedCaption = () => (dispatch: Function, getState: Function) => {
    const { shakaPlayer } = getState();
    const { cc } = shakaPlayer;
    dispatch(shakaPlayerSetClosedCaptionAction(!cc));
};

export const changeAudioTrack = (selectedIndex: number) => () => {
    // TODO: write logic to play the selected audio language
    Log.i('ShakaPlayer', 'Selected audio track: ', selectedIndex);
};

export const stopVideoPlayback =
    (endReason: string) => (dispatch: Function, getState: Function) => {
        const { stationData, metaData } = getState();
        const { channelData } = stationData;
        const { metaData: oMetadata } = metaData;
        const payload: Payload = QoEAppLogger.createWatchContentPayload(channelData, oMetadata);
        payload.add('endReason', endReason);
        QoE.logStreamingEnd(payload);
        QoE.logWatchContentEnd(payload);
    };

export const resetPlaybackRetry = () => (dispatch: Function) => {
    dispatch(shakaPlayerResetRetryAction());
};

export default shakaPlayerReducer;
