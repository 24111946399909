import { AnyAction } from 'redux';
import { Constants } from '../../constants';
import { MetaData } from '../../core/entities/metadata/MetaData';
import { MetaDataRepositoryImpl } from '../../core/infrastructure/metadata/MetaDataRepositoryImpl';
import { MetaDataImpl } from '../../core/usecases/metadata/MetaDataImpl';
import { setDataStandardInfo, showStandardInfoBanner } from '../infobanner/InfoBanner.reducers';
import {
    failureMetaDataAction,
    retryFetchMetaDataAction,
    storeMetaDataAction,
} from './MetaData.actions';
import {
    META_DATA_FETCH_REQUEST,
    META_DATA_FETCH_REQUEST_FAILURE,
    META_DATA_STORE,
    META_DATA_FETCH_REQUEST_RETRY,
} from './MetaData.types';
import { Log } from '../../core/logging/Log';
import { qoeWatchContentStart } from '../shakaplayer/ShakaPlayer.reducers';

const TAG = 'MetaData';

const initialState: {
    metaData: MetaData[];
    retry: number;
} = { metaData: [], retry: 0 };

const metaDataReducer = (state = initialState, { type, payload } = <AnyAction>{}) => {
    switch (type) {
        case META_DATA_FETCH_REQUEST:
            return {
                ...state,
            };

        case META_DATA_FETCH_REQUEST_FAILURE:
            return {
                ...state,
                retry: payload.retry || 0,
            };

        case META_DATA_STORE:
            return {
                ...state,
                metaData: payload.metaData,
                NOW: payload.now,
                NEXT: payload.next,
            };

        case META_DATA_FETCH_REQUEST_RETRY:
            return {
                ...state,
                retry: payload.retry,
            };

        default:
            return state;
    }
};

export const fetchMetaData = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    try {
        const currentTime = Math.floor(new Date().getTime() / 1000);
        const metaDataRepositoryImpl = new MetaDataRepositoryImpl(appl.authToken, appl.stationId);
        const metaDataImpl = new MetaDataImpl(metaDataRepositoryImpl);
        metaDataImpl
            .getMetaData()
            .then((metaData: MetaData[]) => {
                // logic to set now and next station data.
                for (let i = 0; i < metaData.length; i += 1) {
                    if (
                        metaData[i].startTimestamp <= currentTime &&
                        metaData[i].endTimestamp > currentTime
                    ) {
                        // eslint-disable-next-line no-param-reassign
                        metaData = [metaData[i], metaData[i + 1]];
                        dispatch(storeMetaDataAction(metaData));
                        dispatch(setDataStandardInfo());
                        Log.i(TAG, 'MetaData fetched and stored');
                        dispatch(setBoundaryMetaDataTrigger());
                        dispatch(showStandardInfoBanner());
                        dispatch(qoeWatchContentStart());
                        break;
                    }
                }
            })
            .catch(() => {
                dispatch(retryFetchMetaData());
            });
    } catch (error) {
        dispatch(retryFetchMetaData());
    }
};

export const retryFetchMetaData = () => (dispatch: Function, getState: Function) => {
    const { metaData } = getState();
    const { retry } = metaData;

    if (retry < Constants.RETRY_LIMIT) {
        Log.w(TAG, 'METADATA,retryCount ->', retry);
        /**
         * TODO: Need to remove timeout
         */
        setTimeout(() => {
            dispatch(retryFetchMetaDataAction(retry + 1));
            dispatch(fetchMetaData());
        }, Constants.TIMEOUT_VALUE);
    } else {
        dispatch(failureMetaData());
    }
};

export const failureMetaData = () => (dispatch: Function) => {
    dispatch(failureMetaDataAction());
};

export const setBoundaryMetaDataTrigger = () => (dispatch: Function, getstate: Function) => {
    const { metaData } = getstate();
    const { metaData: currentMetadata } = metaData;
    if (currentMetadata.length) {
        const boundaryMetaDataTriggerTime =
            currentMetadata[0].endTimestamp * 1000 - new Date().getTime();
        Log.i(TAG, 'boundaryMetaDataTriggerTime -> ', boundaryMetaDataTriggerTime);
        setTimeout(() => {
            Log.i(TAG, ' refetching metadata');
            dispatch(fetchMetaData());
        }, boundaryMetaDataTriggerTime);
    }
};

export default metaDataReducer;
