import { IApplRepository } from '../../entities/appl/IApplRepository';
import { DEVICE_MAC } from '../../../strings';
import { Log } from '../../logging/Log';

export class ApplRepositoryImpl implements IApplRepository {
    private authSecret: string;

    apsEndpointUrl: string;

    TAG: string = 'ApplRepositoryImpl';

    constructor(apsEndpoint: string, authSecret: string) {
        this.apsEndpointUrl = apsEndpoint;
        this.authSecret = authSecret;
    }

    async getAuthToken(): Promise<string> {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    mac: DEVICE_MAC, // TODO: we need to remove the direct use of device properties.
                    Authorization: this.authSecret,
                },
            };

            const res = await fetch(this.apsEndpointUrl, requestOptions);
            const responseJson = await res.json();
            return responseJson.authToken;
        } catch (error) {
            const message = 'Failed to fetch the APS authorization token';
            Log.e(this.TAG, message, error);
            throw new Error(message);
        }
    }
}
