// APS authorization
export const APS_AUTH_SECRET = 'Basic aG9tZWFwcDpJVmFpVzJvR2hhTTg=';
// APS URLs
export const APS_AUTH_TOKEN_URL = 'https://d3h7wc3xr4x6nw.cloudfront.net/samlprovider2/login';
export const APS_CHANNEL_URL =
    'https://aps-channels-lambda-vaigupta.dev.tivoservice.com/v1/channels';
export const SLS_URL = 'http://sls.tivoservice.com/sls/doClient?type=slsServiceEndpointSearch';
// Currently these are hardcoded, need to be removed once SLS CORS get resolved.
export const APS_GUIDE_URL =
    'https://aps-guide-lambda-vaigupta.dev.tivoservice.com/v1/linearOffers/onNow';

// hard-coded value of tsn will be removed once oyster will provide.
export const BODY_ID = 'tsn:A240000016E85D7';
export const DEVICE_MAC = '00:11:22:33:44:55';
export const TYPE = 'slsServiceEndpointSearch';
export const PROVIDER_ID = 'tivo-plus';
export const TITLE_NOT_AVAILABLE = 'Title Not Available';

// Error dialog: need to be refactor when combining other dialogs
interface MessageOverlayDialog {
    title: string;
    code: string;
    description: string;
}

export const CantPlayOverlay: MessageOverlayDialog = {
    code: 'V701',
    title: "Can't Play",
    description:
        'This title is currently unable to play. Please try again. If you receive this message again, there may be a problem with the show. ',
};

export const CANT_PLAY_OVERLAY_MESSAGE_ID = 'cant-play-message-overlay';

export const CANT_PLAY_OVERLAY_ID = 'cant-play-overlay';

export const StillThereOverlay: MessageOverlayDialog = {
    code: '',
    title: 'Still There?',
    description: 'Press OK to continue watching this show.',
};

export const STILL_THERE_OVERLAY_ID = 'still-there-overlay';
// internet connection lost overlay
export const InternetConnectionLostOverlay: MessageOverlayDialog = {
    code: 'V000',
    title: 'No Internet Connection',
    description:
        'Please check your network connection in Network & Internet Settings. Select OK to exit player.',
};
export const INTERNET_CONNECTION_LOST_OVERLAY_ID = 'internet-connection-lost-overlay';

// info banner
export const INFO_BANNER_ID = 'info-banner';
export const TIME_PHASE_AM = 'am';
export const TIME_PHASE_PM = 'pm';
export const AM = ' AM';
export const PM = ' PM';

// Enter PIN Overlay
// TODO: We need to move the interfaces to the core.
interface PinOverlayDialog {
    title: string;
    description: string;
}

export const EnterPinOverlay: PinOverlayDialog = {
    title: 'Enter PIN',
    description: 'Please enter your 4-digit TiVo+ PIN.',
};

export const IncorrectPinOverlay: PinOverlayDialog = {
    title: 'Incorrect PIN',
    description: 'You have entered an incorrect PIN.  Please try again.',
};

export const IncorrectPinMaxRetryOverlay: PinOverlayDialog = {
    title: 'Incorrect PIN',
    description:
        'You have entered an incorrect PIN. Please try again. To reset your PIN, visit http:/www.tivo.com/mma/parentalcontrols.do',
};

export const ENTER_PIN_OVERLAY_ID = 'enter-pin-overlay';

export const PARENTAL_CONTROL_PIN = '1234';
