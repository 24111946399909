export class Storage {
    static Keys = {
        DEFAULT_STORAGE: localStorage,
        INVALID_PC_PIN_RETRY_COUNT: 'invalidPCPinRetryCount',
    };

    private static storage;

    private storageType;

    private storageObject;

    private storageName = 'TIVOPLUS_STORAGE';

    private constructor(storageType = Storage.Keys.DEFAULT_STORAGE) {
        this.storageType = storageType;
        this.storageObject = {};
    }

    public static getInstance() {
        if (!this.storage) this.storage = new Storage();
        return this.storage;
    }

    store(key: string, value: any): void {
        if (!this.storageObject) this.storageObject = {};
        this.storageObject[key] = value;
        this.update();
    }

    clear(key: string): void {
        if (this.storageObject) delete this.storageObject[key];
        this.update();
    }

    get(key: string): any {
        this.storageObject = JSON.parse(this.storageType.getItem(this.storageName));
        return this.storageObject ? this.storageObject[key] : null;
    }

    deleteStorage(): void {
        if (this.storageType) this.storageType.removeItem(this.storageName);
    }

    private update(): void {
        this.storageType.setItem(this.storageName, JSON.stringify(this.storageObject));
    }
}
