import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import {
    CHANGE_AUDIO_TRACK,
    TURN_SUBTITLES_CC_ON,
    CHANGE_SUBTITLES_CC_LANGUAGE,
} from '../../../../store/infobanner/ActionsStripNavigation.types';
import { SelectionDialog } from '../../../SelectionDialog';
import { RootState } from '../../../../store/root.reducers';
import './PlaybackControls.scss';
import { ActionsStrip } from '../../ActionStrip';

const PlaybackControls: FunctionComponent = (): JSX.Element => {
    const { isPlaybackControlsActive } = useSelector((state: RootState) => state.infoBanner);
    // TODO: remove these hardcoded values after getting the values directly from shaka
    const actionItems = [
        {
            name: CHANGE_AUDIO_TRACK,
            visible: false,
        },
        {
            name: TURN_SUBTITLES_CC_ON,
            visible: true,
        },
        {
            name: CHANGE_SUBTITLES_CC_LANGUAGE,
            visible: false,
        },
    ];
    const actionList = [
        {
            value: 'English',
            isActive: true,
        },
        {
            value: 'Spanish',
            isActive: false,
        },
        {
            value: 'French',
            isActive: false,
        },
    ];
    const { isAudioTrackOverlayActive } = useSelector(
        (state: RootState) => state.audioTrackOverlay,
    );

    return (
        <React.Fragment>
            {isPlaybackControlsActive ? (
                <span className="play-back-controls">Playback controls are not supported</span>
            ) : null}
            <ActionsStrip actionItems={actionItems} />
            {isAudioTrackOverlayActive && (
                <SelectionDialog title="Audio Tracks" optionsList={actionList} />
            )}
        </React.Fragment>
    );
};

export default PlaybackControls;
