import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { RootState } from '../../../store/root.reducers';
import './FullInfo.scss';
import { MetadataFormatter } from '../../../core/infrastructure/MetadataFormatter';
import { InfoTitle } from '../InfoTitle';

interface FullInfoProps {
    id: string;
}

const FullInfo: FunctionComponent<FullInfoProps> = (props): JSX.Element => {
    const { id } = props;
    const currentTime = useSelector((state: RootState) => state.infoBanner.currentTime);
    const metaData = useSelector((state: RootState) => state.metaData.metaData[0]);
    const upNext = useSelector((state: RootState) => state.metaData.metaData[1]);
    const channelData = useSelector((state: RootState) => state.stationData.channelData[0]);

    const dataFormatter = new MetadataFormatter(metaData, channelData);

    let criticRatingDiv;
    if (dataFormatter.hasCriticRating())
        criticRatingDiv = (
            <div className={dataFormatter.getCriticRatingColor(dataFormatter.getCriticRating())}>
                <div className="critic-rating">{dataFormatter.getCriticRating()}</div>
                <div className="percentage">%</div>
                <img className="tmdb" />
            </div>
        );

    const releaseYearDiv = dataFormatter.hasReleaseYear() ? (
        <div className="release-year">{dataFormatter.getReleaseYear()}</div>
    ) : null;

    let genreDiv;
    if (dataFormatter.hasGenre() || dataFormatter.isHideLinearSchedules())
        genreDiv = <div className="genre">{dataFormatter.getGenre()}</div>;

    const durationDiv = dataFormatter.hasDuration() ? (
        <div className="duration">{`${dataFormatter.getStartTime()} - ${dataFormatter.getEndTime()}`}</div>
    ) : null;

    const ratingDiv = dataFormatter.hasRating() ? (
        <img className={`${dataFormatter.getRatings()} rating`}></img>
    ) : null;

    const firstAiredDiv = dataFormatter.hasFirstAired() ? (
        <div className="first-aired">{dataFormatter.getFirstAired()}</div>
    ) : null;

    const upNextDiv = upNext ? (
        <div className="up-next">
            <span className="up-next-text"> {'Up Next: '}</span>
            {upNext.title}
        </div>
    ) : null;

    const metaDataSeperatorDiv = <span className="meta-data-seperator"> &bull; </span>;

    const isDataSeperatorRequried = (check: boolean) => {
        return check ? metaDataSeperatorDiv : null;
    };

    const isMovieContent =
        dataFormatter.hasMetaData() && dataFormatter.getContentType() === 'Movie';
    let fullInfoMetaDataDiv;
    if (isMovieContent) {
        fullInfoMetaDataDiv = (
            <div className="full-info-metadata">
                {criticRatingDiv}
                {isDataSeperatorRequried(dataFormatter.needCriticRatingReleaseYearSeperator())}
                {releaseYearDiv}
                {isDataSeperatorRequried(dataFormatter.needReleaseYearGenreSeperator())}
                {genreDiv}
                {isDataSeperatorRequried(dataFormatter.needGenreDurationSeperator())}
                {durationDiv}
                {isDataSeperatorRequried(dataFormatter.needDurationRatingSeperator())}
                {ratingDiv}
            </div>
        );
    } else {
        fullInfoMetaDataDiv = (
            <div className="full-info-metadata">
                {genreDiv}
                {isDataSeperatorRequried(dataFormatter.needGenreRatingSeperator())}
                {ratingDiv}
                {isDataSeperatorRequried(dataFormatter.needRatingDurationSeperator())}
                {durationDiv}
                {isDataSeperatorRequried(dataFormatter.needDurationFirstAiredSeperator())}
                {firstAiredDiv}
            </div>
        );
    }

    return (
        <div className="full-info-banner" id={id}>
            <div className="info_standardbg" />
            <div className="full-info-left-container">
                <InfoTitle title={dataFormatter.getTitle()} />
                {fullInfoMetaDataDiv}
                <div className="description">{dataFormatter.getDescription()}</div>
                {dataFormatter.hasChannelData() && (
                    <img src={dataFormatter.getChannelLogoUrl()} className="channel-icon"></img>
                )}
                {upNextDiv}
            </div>
            <div className="full-info-right-container">
                <img className="info-brandingicon" style={{ display: 'inline' }} />
                <div className="info-clock">
                    {dataFormatter.createFormattedAMPMTime(currentTime)}
                </div>
            </div>
        </div>
    );
};

FullInfo.propTypes = {
    id: PropTypes.string.isRequired,
};

export default FullInfo;
