import React, { FunctionComponent, useEffect } from 'react';
import PropTypes from 'prop-types';
import './SelectionDialog.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/root.reducers';
import { setOverlayHighlightVerticalLimitAction } from '../../store/overlay/overlayhighlight/overlayhighlight.actions';
import SelectMark from '../../assets/images/check.png';

interface SelectionDialogProps {
    title: string;
    optionsList: Array<any>;
}

const SelectionDialog: FunctionComponent<SelectionDialogProps> = (props): JSX.Element => {
    const { title, optionsList } = props;
    const { verticalPosition } = useSelector((state: RootState) => state.overlayHighlight);
    const dispatch = useDispatch();

    useEffect(() => {
        if (optionsList?.length) {
            dispatch(setOverlayHighlightVerticalLimitAction(optionsList.length));
        }
        return () => {
            dispatch(setOverlayHighlightVerticalLimitAction(0));
        };
    }, [optionsList]);

    return (
        <div className="selection-dialog">
            <div className="dialog-title">{title}</div>
            <div className="dialog-divider" />
            <div className="options-body-container">
                {optionsList.map((actionItem, index) => {
                    const isFocused = verticalPosition === index;
                    return (
                        <div key={index} className={`options-list ${isFocused ? 'highlight' : ''}`}>
                            {actionItem.isActive && (
                                <img src={SelectMark} className="select-mark-button-icon" />
                            )}
                            <span className={`list-values ${isFocused ? 'highlight-value' : ''}`}>
                                {actionItem.value}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

SelectionDialog.propTypes = {
    title: PropTypes.string.isRequired,
    optionsList: PropTypes.any.isRequired,
};

export default SelectionDialog;
