import { combineReducers } from 'redux';
import appl from './appl/Appl.reducers';
import slsServiceEndpointSearch from './sls/SlsServiceEndpointSearch.reducers';
import shakaPlayer from './shakaplayer/ShakaPlayer.reducers';
import stationData from './stationdata/StationData.reducers';
import metaData from './metadata/MetaData.reducers';
import messageOverlay from './messageoverlay/MessageOverlay.reducers';
import infoBanner from './infobanner/InfoBanner.reducers';
import inputFactory from './inputfactory/inputFactory.reducer';
import pinOverlay from './pinoverlay/PinOverlay.reducers';
import audioTrackOverlay from './overlay/audiotrackoverlay/audiotrackoverlay.reducers';
import overlayHighlight from './overlay/overlayhighlight/overlayhighlight.reducers';

const RootReducer = combineReducers({
    appl,
    slsServiceEndpointSearch,
    shakaPlayer,
    stationData,
    metaData,
    messageOverlay,
    infoBanner,
    inputFactory,
    pinOverlay,
    audioTrackOverlay,
    overlayHighlight,
});

export default RootReducer;

export type RootState = ReturnType<typeof RootReducer>;
