import { AnyAction } from 'redux';
import { ChannelData } from '../../core/entities/stationdata/ChannelData';
import { StationDataRepositoryImpl } from '../../core/infrastructure/stationdata/StationDataRepositoryImpl';
import { StationDataImpl } from '../../core/usecases/stationdata/StationDataImpl';
import { Constants } from '../../constants';
import { fetchMetaData } from '../metadata/MetaData.reducers';
import {
    storeStationDataAction,
    retryFetchStationDataAction,
    failureStationDataAction,
} from './StationData.actions';
import {
    STATION_DATA_FETCH_REQUEST,
    STATION_DATA_FETCH_REQUEST_FAILURE,
    STATION_DATA_STORE,
    STATION_DATA_FETCH_REQUEST_RETRY,
} from './StationData.types';
import { qoeWatchContentStart, startVideoPlayback } from '../shakaplayer/ShakaPlayer.reducers';
import { cantPlayOverlayShow } from '../messageoverlay/MessageOverlay.reducers';
import { showStandardInfoBanner } from '../infobanner/InfoBanner.reducers';
import { Log } from '../../core/logging/Log';

const TAG = 'stationDataReducer';

const initialState: {
    channelData: ChannelData[];
    retry: number;
    shakaPlayUrl: string;
} = { channelData: [], retry: 0, shakaPlayUrl: '' };

const stationDataReducer = (state = initialState, { type, payload } = <AnyAction>{}) => {
    switch (type) {
        case STATION_DATA_FETCH_REQUEST:
            return {
                ...state,
            };

        case STATION_DATA_FETCH_REQUEST_FAILURE:
            return {
                ...state,
                retry: payload.retry || 0,
            };

        case STATION_DATA_STORE:
            return {
                ...state,
                channelData: payload.channelData,
                shakaPlayUrl: payload.shakaPlayUrl,
            };

        case STATION_DATA_FETCH_REQUEST_RETRY:
            return {
                ...state,
                retry: payload.retry,
            };

        default:
            return state;
    }
};

export const fetchStationData = () => (dispatch: Function, getState: Function) => {
    const { appl } = getState();
    try {
        const stationDataRepositoryImpl = new StationDataRepositoryImpl(
            appl.authToken,
            appl.stationId,
        );
        const stationDataImpl = new StationDataImpl(stationDataRepositoryImpl);
        stationDataImpl.getStationData().then((channelData: ChannelData[]) => {
            const shakaPlayUrl = formPlayUrl(channelData[0]);
            dispatch(startVideoPlayback(shakaPlayUrl));
            dispatch(storeStationDataAction(channelData, shakaPlayUrl));
            if (!channelData[0].hideLinearSchedules) dispatch(fetchMetaData());
            else {
                dispatch(qoeWatchContentStart());
                dispatch(showStandardInfoBanner());
            }
        });
    } catch (error) {
        dispatch(retryFetchStationData());
    }
};

export const retryFetchStationData = () => (dispatch: Function, getState: Function) => {
    const { stationData } = getState();
    const { retry } = stationData;

    if (retry < Constants.RETRY_LIMIT) {
        Log.w(TAG, 'APS-Channel,retryCount ->', retry);
        /**
         * TODO: Need to remove timeout
         */
        setTimeout(() => {
            dispatch(retryFetchStationDataAction(retry + 1));
            dispatch(fetchStationData());
        }, Constants.TIMEOUT_VALUE);
    } else {
        dispatch(failureStationData());
        dispatch(cantPlayOverlayShow());
    }
};

export const failureStationData = () => (dispatch: Function) => {
    dispatch(failureStationDataAction());
};

const formPlayUrl = (stationData: ChannelData) => {
    return `${stationData.playUrl}?contenttype=linear&p=tivo&uxloc=in-app&appname=TiVoPlus-Atom&adunittype=video&devtype=dpid&videotype=mid-roll&apptype=entertainment&partnername=${stationData.providerName}&mvpd=tivo&appver=22&devid=6e5d383a-94d9-47b0-ad6e-80e68cff67c7&sz=${window.innerWidth}x${window.innerHeight}&channelname=${stationData.channelNameEncoded}&devlat=0`;
};

export default stationDataReducer;
