import {
    ISageTransportParams,
    Logger,
    Payload,
    QoE,
    SageTransport,
} from '@tivocorp/tivo-webapp-loggers';
import { VIDEO_PLAYER, VIDEO_SOURCE } from '../../config';
import { AppUtil } from '../utils/AppUtil';
import { IDeviceParams } from './IDeviceParams';
import { MetaData } from '../entities/metadata/MetaData';
import { ChannelData } from '../entities/stationdata/ChannelData';

export class QoEAppLogger {
    private static streamingSessionId: string = AppUtil.getUuid();

    private static watchContentId: string = AppUtil.getUuid();

    private static sessionId: string = AppUtil.getUuid();

    private static url: string = '';

    static createSessionId() {
        QoEAppLogger.sessionId = AppUtil.getUuid();
    }

    static createStreamingSessionId() {
        QoEAppLogger.streamingSessionId = AppUtil.getUuid();
    }

    static createWatchContentId() {
        QoEAppLogger.watchContentId = AppUtil.getUuid();
    }

    static setVideoPlayerUrl(playUrl) {
        QoEAppLogger.url = playUrl;
    }

    static createPlayerStatePayload() {
        const payload: Payload = new Payload();
        payload.add('videoPlayer', VIDEO_PLAYER);
        payload.add('startTime', Date.now());
        payload.add('videoPlayerURL', QoEAppLogger.url);
        return payload;
    }

    static createSessionStartedPayload() {
        QoEAppLogger.createSessionId();
        const payload: Payload = new Payload(QoEAppLogger.createPlayerStatePayload());
        payload.add('sessionId', QoEAppLogger.sessionId);
        return payload;
    }

    static createStreamingSessionPayload(channelData: ChannelData[], metaData: MetaData[]) {
        const payload: Payload = QoEAppLogger.createTransPayload(channelData, metaData);
        payload.add('streamingSessionTransactionId', QoEAppLogger.streamingSessionId);
        return payload;
    }

    static createTransPayload(channelData: ChannelData[], metaData: MetaData[]) {
        const transPayload = <JSON>(<unknown>{
            channelId: channelData[0].stationId || '',
            channelName: channelData[0].channelName || '',
            channelNumber: channelData[0].channelNumber || '',
            collectionId: '',
            collectiontitle: '',
            contentId: metaData[0].contentId || '',
            contentTitle: metaData[0].title || '',
            videoPlayer: VIDEO_PLAYER,
            videoPlayerUrl: channelData[0].playUrl || '',
            videoSource: VIDEO_SOURCE,
        });
        return new Payload(transPayload);
    }

    static createWatchContentPayload(channelData: ChannelData[], metaData: MetaData[]) {
        const payload: Payload = QoEAppLogger.createTransPayload(channelData, metaData);
        payload.add('watchContentTransactionId', QoEAppLogger.watchContentId);
        return payload;
    }

    static setSageTransportParams(sageParams: ISageTransportParams) {
        const logger: Logger = QoE.getInstance();
        const sageTransport: SageTransport = new SageTransport(sageParams);
        logger.setTransport(sageTransport);
    }

    static setGlobalParams(deviceParams: IDeviceParams) {
        const globalParam = <JSON>(<unknown>{
            adTrackingEnabled: deviceParams.adTrackingEnabled,
            appName: deviceParams.appName,
            appVersion: deviceParams.appVersion,
            applicationSessionId: AppUtil.getSessionID(),
            connection: deviceParams.connection,
            deviceId: deviceParams.deviceId,
            deviceType: deviceParams.deviceType,
            deviceTsn: deviceParams.deviceTsn,
            locale: AppUtil.getUserLocale(),
            loggingVersion: '2.0.1',
            msoName: deviceParams.msoName,
            msoPartnerId: deviceParams.msoPartnerId,
            timezone: AppUtil.getUserTimezone(),
            userId: deviceParams.userId,
            uuid: AppUtil.getUuid(),
        });

        const globalPayload: Payload = new Payload(globalParam);
        QoE.setGlobals(globalPayload);
    }

    static PlaybackEndReasons = {
        ENDED_BY_USER: 'ENDED_BY_USER',
        END_OF_CONTENT: 'END_OF_CONTENT',
        LOST_NETWORK: 'LOST_NETWORK',
        PLAYER_ERROR: 'PLAYER_ERROR',
        VIDEOPLAYER_ERROR: 'VIDEOPLAYER_ERROR',
    };
}
