export class AppUtil {
    /**
     * Method will return UUID.
     */
    /* eslint-disable no-bitwise */
    static getUuid = (a: string = ''): string =>
        // eslint-disable-next-line
        a
            ? ((Number(a) ^ (Math.random() * 16)) >> (Number(a) / 4)).toString(16)
            : `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, this.getUuid);

    /**
     * Method will return the Session ID.
     */
    static getSessionID = (): string => {
        return this.getUuid();
    };

    /**
     * Method will return the user locale.
     * Example: en-US, en-UK
     */
    static getUserLocale = () => {
        return window.navigator.languages[0] || window.navigator.language || '';
    };

    /**
     * Method  will return the user timezone.
     * Example: GMT+0530 (India Standard Time)
     */
    static getUserTimezone = () => {
        return (
            new Date().toTimeString().slice(9) ||
            Intl.DateTimeFormat().resolvedOptions().timeZone ||
            ''
        );
    };

    /**
     * Method will return a string of the standard time in following format:
     * YYYY-mm-dd HH:MM:SS timezone (timezone name)
     * Example: 2021-02-25 23:09:43 GMT+0530 (India Standard Time)
     */
    static getStandardTimestamp = () => {
        const date = new Date();
        const month = date.getMonth() + 1 < 10 ? `0+ ${date.getMonth()}+1` : `${date.getMonth()}+1`;
        return `${date.getFullYear()}-${month}-${date.getDate()} ${date.toTimeString()}`;
    };
}
