import { NavigationHandlers } from '../../core/infrastructure/navigation/NavigationTypes';
import { setNavigationHandler } from '../appl/Appl.reducers';
import { showAudioTrackOverlay } from '../overlay/audiotrackoverlay/audiotrackoverlay.reducers';
import { toggleClosedCaption } from '../shakaplayer/ShakaPlayer.reducers';
import {
    CHANGE_AUDIO_TRACK,
    CHANGE_SUBTITLES_CC_LANGUAGE,
    TURN_SUBTITLES_CC_ON,
} from './ActionsStripNavigation.types';
import { setIsOverlayActive } from './InfoBanner.actions';
import {
    hideStandardInfoBanner,
    hideTrickPlayBar,
    setActionsStripHighlightIndex,
} from './InfoBanner.reducers';

export const onKeyLeftActionsStrip = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { actionsStripHighlightIndex } = infoBanner;
    if (actionsStripHighlightIndex > 0)
        dispatch(setActionsStripHighlightIndex(actionsStripHighlightIndex - 1));
};

export const onKeyOkActionsStrip = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { availableActionItems, actionsStripHighlightIndex } = infoBanner;
    switch (availableActionItems[actionsStripHighlightIndex].name) {
        case CHANGE_AUDIO_TRACK:
            dispatch(setNavigationHandler(NavigationHandlers.OverlayNavigationHandler));
            dispatch(showAudioTrackOverlay());
            dispatch(setIsOverlayActive(true));
            break;
        case CHANGE_SUBTITLES_CC_LANGUAGE:
            // dispatch(setNavigationHandler(NavigationHandlers.OverlayNavigationHandler));
            // dispatch(showSubtitleAndCCLanguageOverlay());
            // dispatch(setIsOverlayActive(true));
            break;
        case TURN_SUBTITLES_CC_ON:
            dispatch(toggleClosedCaption());
            break;

        default:
        // callback to default case
    }
};

export const onKeyRightActionsStrip = () => (dispatch: Function, getState: Function) => {
    const { infoBanner } = getState();
    const { actionsStripHighlightIndex, actionItemsCount } = infoBanner;
    if (actionsStripHighlightIndex < actionItemsCount - 1)
        dispatch(setActionsStripHighlightIndex(actionsStripHighlightIndex + 1));
};

export const onKeyBackActionsStrip = () => (dispatch: Function) => {
    dispatch(setNavigationHandler(NavigationHandlers.ApplHandler));
    dispatch(hideTrickPlayBar());
    dispatch(hideStandardInfoBanner());
};
