import { AnyAction } from 'redux';
import {
    CANT_PLAY_OVERLAY_SHOW,
    CANT_PLAY_OVERLAY_HIDE,
    STILL_THERE_OVERLAY_SHOW,
    STILL_THERE_OVERLAY_HIDE,
    INTERNET_CONNECTION_OVERLAY_SHOW,
    INTERNET_CONNECTION_OVERLAY_HIDE,
} from './MessageOverlay.types';
import {
    cantPlayOverlayHideAction,
    cantPlayOverlayShowAction,
    internetConnectionLostOverlayHideAction,
    internetConnectionLostOverlayShowAction,
    stillThereOverlayHideAction,
    stillThereOverlayShowAction,
} from './MessageOverlay.actions';
import { Log } from '../../core/logging/Log';

const TAG: string = 'MessageOverlay';

const initialState: {
    isCantPlayOverlayActive: boolean;
    isStillThereOverlayActive: boolean;
    isInternetConnectionLostOverlayActive: boolean;
} = {
    isCantPlayOverlayActive: false,
    isStillThereOverlayActive: false,
    isInternetConnectionLostOverlayActive: false,
};

const messageOverlayReducer = (state = initialState, { type, payload } = <AnyAction>{}) => {
    switch (type) {
        case CANT_PLAY_OVERLAY_SHOW:
        case CANT_PLAY_OVERLAY_HIDE:
            return {
                ...state,
                isCantPlayOverlayActive: payload.isCantPlayOverlayActive,
            };

        case STILL_THERE_OVERLAY_SHOW:
        case STILL_THERE_OVERLAY_HIDE:
            return {
                ...state,
                isStillThereOverlayActive: payload.isStillThereOverlayActive,
            };
        case INTERNET_CONNECTION_OVERLAY_SHOW:
        case INTERNET_CONNECTION_OVERLAY_HIDE:
            return {
                ...state,
                isInternetConnectionLostOverlayActive:
                    payload.isInternetConnectionLostOverlayActive,
            };
        default:
            return state;
    }
};

export const cantPlayOverlayShow = () => (dispatch: Function, getState: Function) => {
    const { messageOverlay } = getState();
    const { isStillThereOverlayActive, isInternetConnectionLostOverlayActive } = messageOverlay;
    if (!isStillThereOverlayActive && !isInternetConnectionLostOverlayActive) {
        dispatch(cantPlayOverlayShowAction());
        Log.i(TAG, `Can't Play Overlay is on Top`);
    }
};

export const cantPlayOverlayHide = () => (dispatch: Function) => {
    dispatch(cantPlayOverlayHideAction());
    Log.i(TAG, `Can't Play Overlay Disappeared`);
};

export const stillThereOverlayShow = () => (dispatch: Function, getState: Function) => {
    const { messageOverlay } = getState();
    const { isCantPlayOverlayActive, isInternetConnectionLostOverlayActive } = messageOverlay;
    if (isCantPlayOverlayActive) dispatch(cantPlayOverlayHide());
    if (!isInternetConnectionLostOverlayActive) {
        dispatch(stillThereOverlayShowAction());
        Log.i(TAG, `Still There Overlay is on Top`);
    }
};

export const stillThereOverlayHide = () => (dispatch: Function) => {
    dispatch(stillThereOverlayHideAction());
    Log.i(TAG, `Still There Overlay Disappeared`);
};

export const internetConnectionLostOverlayShow = () => (dispatch: Function, getState: Function) => {
    const { messageOverlay } = getState();
    const { isCantPlayOverlayActive, isStillThereOverlayActive } = messageOverlay;
    if (isCantPlayOverlayActive) dispatch(cantPlayOverlayHide());
    if (isStillThereOverlayActive) dispatch(stillThereOverlayHide());
    dispatch(internetConnectionLostOverlayShowAction());
};

export const internetConnectionLostOverlayHide = () => (dispatch: Function) => {
    dispatch(internetConnectionLostOverlayHideAction());
};

export default messageOverlayReducer;
