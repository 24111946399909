export const APP_NAME = 'TiVoPlus-Oyster';
export const APP_VERSION = '0.1.0';
export const DEVICE_ID = '00:11:22:33:44:55';
export const AD_TRACKING_ENABLED = false;
export const DEVICE_TYPE = 'Smart-TV';
export const DEVICE_TSN = 'tsn:A240000016E85D7';
export const MSO_NAME = 'TiVo';
export const CONNECTION = 'Unknown';
export const MSO_PARTNER_ID = '3095';
export const USER_ID = '00000000-0000-0000-0000-000000000000';
export const VIDEO_PLAYER = 'ShakaPlayer';
export const VIDEO_SOURCE = 'IPTV';
